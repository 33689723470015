<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 2020年3月16日11:35:54
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="page">
    
  </div>
</template>

<script>
  export default {
    components: {

    },
    data() {
      return {
        
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped="" lang="scss">
  .page{
    width: 100vw;
    height: 100vh;
    background: #000;
  }
</style>
